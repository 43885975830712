<template>
   <MobileHeader></MobileHeader>
  <div class="container has-header has-space" ref="scrollComponent">
      <div class="display-flex news__author">
        <div class="news__author-wrapper">
            <img :src="`${s3ImgUrl}/author/${authorId}.png`" class="news__author-icon">
            <span>{{newsAuthorInfo.authorName}}</span>
        </div>
        <div class="news__author-list__wrapper">
             <router-link :to="{ name: 'newsDetail', params: { title:o.urlName, id: o.id, currentSportType: o.sportTypePath }}"  v-for="(o, index) in newsAuthorList" :key="index" class="news__author-list__container">
                <h1 class="news__author-list__title">{{o.title}}</h1>
                <div class="news__author-list__info">
                    <div>
                        <span class="news__author-list__tag">{{o.publishedOn}}</span>
                    </div>
                </div>
                <div class="mb-3rem news__author-list__content-wrapper">
                    <div v-html="o.content" class="news__author-list__content"></div>
                    <div class="news__author-list__content-blur"><div>{{$t('READ_MORE')}}</div></div>
                </div>
             </router-link>
        </div>
      </div>
  </div>
</template>

<script>
import moment from 'moment'

import config from '@/js/config.js'
import MobileHeader from '@/components/mobile/Header.vue'

import {mapGetters,mapActions} from 'vuex'
export default {
    components:{
		MobileHeader     
    },
    data() {
        return {
            s3ImgUrl: config.s3ImgUrl,
            newsAuthorList: [],
            newsAuthorInfo :{
                name: ''
            },
            page: {
                pageNo: 1,
                pageSize: config.newsPageSize,
                totalPage: 1
            },
            isFirstTime: false,
            isLoadMore: false,
            apiReturned: false,
        }
    },
     watch:{
		$route() {
            if (this.$route.name === 'newsAuthor') {
                this.init();
            } 
        },
	},
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    created() {
        this.authorId = this.$route.params.id
    },
     computed:{
        ...mapGetters([              
                'isMobile',
                'currentLanguageObj',
                'currentSportType'
           ]),
    },
    mounted() {
      this.init();
    },
    methods: {
         ...mapActions([
            "getNewsListByAuthor",
        ]),

        async getNewsListByAuthorData(isFirstTime){
            let params = {                   
                pageNo: this.page.pageNo,
                pageSize: this.page.pageSize,
                // language: this.currentLanguageObj.locale,
                // lang: this.currentLanguageObj.apiParam,
                authorId: this.authorId
            }
            const result = await this.getNewsListByAuthor(params);
            // YIM - flag for only load new page after last page returned and scrolled to end of page
            this.apiReturned = true;
            this.page.totalPage = result.result.news.totalPages;
            this.newsAuthorInfo.authorName = result.result.authorName;

            result.result.news.content.forEach(x=>{
                 x.urlName = x.title.toLowerCase().replace(/\s/g, '-')
                x.publishedOn = moment(x.publishedOn).fromNow();

                if (x.sportType == "SOCCER") {
                    x.sportTypePath = "football";
                } else {
                    x.sportTypePath = x.sportType.toLowerCase();
                }
            })

            if (this.isLoadMore) {
                result.result.news.content.forEach(x => {
                    this.newsAuthorList.push(x);
                });
            } else {
                this.newsAuthorList = result.result.news.content;
            }

            if (isFirstTime) {
                window.addEventListener('scroll', this.handleScroll);
            }
        },

        handleScroll() {
            let element = this.$refs.scrollComponent;
            if (element.getBoundingClientRect().bottom < window.innerHeight && this.apiReturned) {
                this.apiReturned = false;
                this.loadMorePosts();
            }
        },

        loadMorePosts() {
            this.page.pageNo++;
            this.isLoadMore = true;
            if (this.page.totalPage >= this.page.pageNo) {
                this.getNewsListByAuthorData();
            }
        },

        init(){
            this.getNewsListByAuthorData(true);
        }

    },
}
</script>

<style scoped>
.news__author{
        width: 1100px;
    margin: 2rem auto;
}
.news__author-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 13.5rem;
}
.news__author-icon{
    width: 4.18rem;
    height: 4.18rem;
    margin-bottom: 1.91rem;
}
.news__author-list__title{
    font-size: 2.125rem;
    margin: 2.8125rem 0;
}

.news__author-list__container{
    cursor: pointer;
    border-bottom: 1px solid var(--color-grey-60);
}
.news__author-list__container:first-of-type .news__author-list__title{
  margin-top: 0;
}
.news__author-list__wrapper{
    display: flex;
    flex-direction: column;
   width: 817px;
}
.news__author-list__info{
    margin-bottom: 2.875rem;
}
.news__author-list__tag{
	font-size: 0.6rem;
}
.news__author-list__tag::after{
	content: '|';
	width: .1rem;
	height: 1rem;
	margin: 0 .5rem;
}
.news__author-list__tag:last-of-type::after {
	display: none;
}
.news__author-list__content-wrapper{
    max-height: 20rem;
    overflow: hidden;
    position: relative;
    pointer-events: none;
}
.news__author-list__content{
    min-height: 3.5rem;
}

.news__author-list__content-blur{
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    width: 100%;
    height: 3.5rem;
    pointer-events: none;
    background-image: linear-gradient(to bottom, #303a3e5c, var(--bg-theme));
    z-index: 2;
    justify-content: center;
    align-items: center;
    color: var(--bg-selected);
}
 
@media (max-width: 768px){
    .news__author{
        flex-direction: column;
        width: auto;
    }
    .news__author-list__content-wrapper{
        margin-bottom: 1rem;
    }
    .news__author-wrapper{
        flex-direction: row;
        margin: 1.5rem 0 1rem;
    }
    .news__author-icon{
        width: 2.18rem;
        height: 2.18rem;
        margin-right: 1rem;
        margin-bottom: 0;
    }
    .news__author-list__info{
        margin-bottom: 1rem;
    }
    .news__author-list__title{
        font-size: 1.125rem;
        margin-bottom: 0.5rem;
        margin-top: 0;
    }
    .news__author-list__container{
        border-bottom: .1rem solid var(--color-grey-60);
        margin-bottom: 1rem;
    }
    .news__author-list__wrapper{
         width: auto;
    }
}

</style>